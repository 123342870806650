import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFeedNews, GetFeedNewsParams } from "../../../features/news/domain/useCases/getFeedNews";
import { container } from "../../../product/di_containers/inversify.config";
import { Failure } from "../../../core/error/failure";
import { AppConst } from "../../../product/constants/appConst";
import { NewsModel } from "../../../product/models/news/newsModel";
import { CategoryModel } from "../../../product/models/category/categoryModel";
import { GetUserCategories } from "../../../features/category/domain/useCases/getCategories";
import { CategoryTypes } from "../../../features/category/categoryTypes";
import { NewsContainerTypes } from "../../../features/news/newsContainerTypes";
import { FeedState } from "./FeedState";
import { RootState } from "../../index";

const initialState: FeedState = {
  news: [],
  categories: [new CategoryModel(0, "Tüm haberler")],
  page: 1,
  categoryId: 0,
  getNewsAttempts: 0,
  loading: false,
  isLastPage: false,
  isEmptyListFetched: false,
};

export const fetchFeedNews = createAsyncThunk<
  NewsModel[],
  void,
  {
    rejectValue: string;
  }
>("news/fetchFeedNews", async (_, thunkAPI) => {
  try {
    const feedState = (thunkAPI.getState() as RootState).feed;

    const getFeedNewsUseCase = container.get<GetFeedNews>(NewsContainerTypes.GetFeedNews);

    const feedNews = await getFeedNewsUseCase.execute(
      new GetFeedNewsParams({ categoryId: feedState.categoryId, page: feedState.page })
    );

    return feedNews.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message),
      async (news: NewsModel[]) => {
        // Check if news is empty and getNewsAttempts is below maxAttempts
        if (news.length === 0 && feedState.getNewsAttempts < AppConst.maxRequestAttempts) {
          // Re-dispatch fetchFeedNews to attempt another load
          thunkAPI.dispatch(incrementNewsAttempts()); // Increment attempts
          thunkAPI.dispatch(fetchFeedNews()); // Retry the fetch
          return thunkAPI.fulfillWithValue([]); // Temporarily fulfill with an empty array
        }
        return thunkAPI.fulfillWithValue(news); // Return news if fetched successfully
      }
    );
  } catch (e) {
    console.log(e);
    return thunkAPI.rejectWithValue("An unexpected error occurred");
  }
});

export const fetchUserCategories = createAsyncThunk<
  CategoryModel[],
  void,
  {
    rejectValue: string;
  }
>("news/fetchUserCategories", async (_, thunkAPI) => {
  const getUserCategoriesUseCase = container.get<GetUserCategories>(CategoryTypes.GetUserCategories);
  const categories = await getUserCategoriesUseCase.execute();
  return categories.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (categories: CategoryModel[]) => thunkAPI.fulfillWithValue(categories)
  );
});

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    resetFeed: (state) => {
      state.news = [];
      state.page = 1;
      state.isLastPage = false;
      state.isEmptyListFetched = false;
      state.getNewsAttempts = 0;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    incrementNewsAttempts(state) {
      state.getNewsAttempts += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeedNews.fulfilled, (state, action) => {
        state.page += 1;
        // Increment the page if new news is fetched, reset attempts
        if (isPayloadNotEmpty(action.payload)) {
          const newNews = action.payload.filter(
            (newItem: NewsModel) => !state.news.some((existingItem) => existingItem.id === newItem.id)
          );
          state.news.push(...newNews);
          state.getNewsAttempts = 0; // Reset attempts on successful fetch
          state.isLastPage = false; // More pages to fetch
          state.loading = false;
        } else if (state.getNewsAttempts >= AppConst.maxRequestAttempts) {
          // Mark as last page if max attempts are reached
          state.isLastPage = true;
          state.loading = false;
        }

        // Handle cases where empty list has been fetched but not yet reached max attempts
        state.isEmptyListFetched = action.payload.length === 0;
      })
      .addCase(fetchFeedNews.rejected, (state) => {
        state.loading = false;
        state.isLastPage = true;
      })
      .addCase(fetchUserCategories.fulfilled, (state, action) => {
        state.categories = [new CategoryModel(0, "Tüm haberler"), ...action.payload];
      });
  },
});

const isPayloadNotEmpty = (payload: any[]): boolean => Array.isArray(payload) && payload.length !== 0;
export const { incrementNewsAttempts } = feedSlice.actions;
export const { resetFeed, setCategoryId } = feedSlice.actions;
export default feedSlice.reducer;
