import React from "react";
import "./ActionButtonRow.css";
import { ReactComponent as CommentIcon } from "../../../../product/assets/iconsV2/outlined/comment.svg";
import { ReactComponent as EyeIcon } from "../../../../product/assets/iconsV2/outlined/eye_open.svg";
import { ReactComponent as FilledVerificationIcon } from "../../../../product/assets/iconsV2/filled/verify.svg";
import { ReactComponent as OutlinedVerificationIcon } from "../../../../product/assets/iconsV2/outlined/verify.svg";
import { ReactComponent as FilledDenyIcon } from "../../../../product/assets/iconsV2/filled/deny.svg";
import { ReactComponent as OutlinedDenyIcon } from "../../../../product/assets/iconsV2/outlined/deny.svg";
import { ReactComponent as Bookmark } from "../../../../product/assets/iconsV2/outlined/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "../../../../product/assets/iconsV2/filled/bookmark.svg";
import ShareSocial from "../../../../components/share/share_social";

interface ActionButtonRowProps {
  verificationCount?: number;
  denyCount?: number;
  commentCount?: number;
  shareCount?: number;
  viewCount: number | undefined;
  isVerified?: boolean;
  isDenied?: boolean;
  isBookmarked: boolean | false;
  newsTitle: string | undefined;
  url: string | undefined;
}

const ActionButtonRow: React.FC<ActionButtonRowProps> = ({
  verificationCount,
  denyCount,
  commentCount,
  isVerified,
  isDenied,
  isBookmarked,
  newsTitle,
  viewCount,
  url,
}) => (
  <div className='action-button-row'>
    <div className='action-button-row-left'>
      <span className='news-details-button-group'>
        {isVerified ? <FilledVerificationIcon className='verified-icon' /> : <OutlinedVerificationIcon />}
        <div className='news-details-counts'>{verificationCount}</div>
      </span>
      <span className='news-details-button-group'>
        {isDenied ? <FilledDenyIcon className='denied-icon' /> : <OutlinedDenyIcon />}
        <div className='news-details-counts'>{denyCount}</div>
      </span>
      <span className='news-details-icon-group'>
        <CommentIcon />
        <div className='news-details-counts'>{commentCount}</div>
      </span>{" "}
      <span className='news-details-icon-group'>
        <EyeIcon />
        <div className='news-details-counts'>{viewCount}</div>
      </span>
    </div>
    <div className='action-button-row-right'>
      <span className='news-details-button-group'>
        <ShareSocial title={newsTitle || ""} url={url || ""} />
      </span>
      <span className='news-details-button-group'>{isBookmarked ? <BookmarkFilled /> : <Bookmark />}</span>
    </div>
  </div>
);

export default ActionButtonRow;
