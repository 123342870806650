import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../stores/hooks";
import { fetchNewsDetails, resetNewsDetailsState } from "../../../stores/news/newsDetails/newsDetailsSlice";
import NewsDetailsShimmer from "./components/NewsDetailsShimmer"; // Shimmer loading component
import NewsDetailsError from "./components/NewsDetailsError"; // Error message component
import UserDetailRow from "./components/UserDetailRow";
import ContentBlockList from "./components/ContentBlockList";
import "./NewsDetailsV2.css";
import ActionButtonRow from "./components/ActionButtonRow";
import DateTimeUtil from "../../../product/utils/dateTime/DateTimeUtil";
import NewsDetailsSEO from "./NewsDetailsSEO";
import { Flip, toast, ToastContainer } from "react-toastify";

const NewsDetailsV2: React.FC = () => {
  const { param } = useParams<{ param: string }>();

  const handleAdClick = () => {
    window.location.href = "https://yildizskylab.com/";
  };
  const dispatch = useAppDispatch();
  const { news, loading, error, isBookmarked, isDenied, isVerified } = useAppSelector((state) => state.newsDetails);

  const copyToClipboard = (text: string | undefined) => {
    if (!text) {
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Kopyalandı");
    });
  };

  useEffect(() => {
    if (!news) {
      dispatch(fetchNewsDetails(param || ""));
    }
  }, [dispatch, param, news]);

  useEffect(() => {
    return () => {
      dispatch(resetNewsDetailsState());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div>
        <NewsDetailsSEO
          title={news?.title || "News Details"}
          description={news?.description || "Detailed news information"}
          keywords={news?.categories?.map((c) => c.name).join(", ") || "news, details"}
          url={`https://jurnalle.com/news-details/${param}`}
          image={news?.thumbnail || "default-image.jpg"}
          author={news?.user?.nickname || "Author"}
        />
        <NewsDetailsShimmer />
      </div>
    );
  }
  if (error) {
    return <NewsDetailsError message={error} />;
  }

  function getActionButtonRow() {
    return (
      <ActionButtonRow
        verificationCount={news?.verificationCount}
        denyCount={news?.denialCount}
        commentCount={news?.commentCount}
        viewCount={news?.viewCount}
        isVerified={isVerified}
        isDenied={isDenied}
        isBookmarked={isBookmarked}
        url={`https://jurnalle.com/news-details/${param}`}
        newsTitle={news?.title}
      />
    );
  }

  return (
    <div className='news-details-container'>
      {news && (
        <NewsDetailsSEO
          title={news.title || "News Details"}
          description={news.description || "Detailed news information"}
          keywords={news.categories?.map((c) => c.name).join(", ") || "news, details"}
          url={`https://jurnalle.com/news-details/${param}`}
          image={news.thumbnail || "default-image.jpg"}
          author={news.user?.nickname || "Author"}
        />
      )}
      <div className='news-details-ad' onClick={handleAdClick}>
        <img src='/assets/skylab.png' alt='Skylab' />
      </div>
      <h1 className='news-title'>{news?.title}</h1>
      {news?.location?.cityCountry && <p className='news-location'>{news.location.cityCountry}</p>}
      <p className='news-date'>
        Yayınlama: {DateTimeUtil.parseDateTimeToHourMinute(news?.createdAt)}
        {news?.editedAt && ` • Düzenleme: ${news.editedAt}`}
      </p>
      {getActionButtonRow()}
      <UserDetailRow user={news?.user} />
      <ContentBlockList contentBlocks={news?.contentBlocks || []} />
      <div className='news-categories'>
        {news?.categories?.map((category) => (
          <p key={category.id} className='category-tag'>
            {category.name}
          </p>
        ))}
      </div>
      {news?.referenceUrl && (
        <div className='source-container'>
          Kaynak: <a onClick={() => copyToClipboard(news.referenceUrl)}>{news.referenceUrl}</a>
        </div>
      )}
      {getActionButtonRow()}
      <div className='news-details-empty-space'></div>
      <ToastContainer
        position='top-center'
        autoClose={700}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
        transition={Flip}
      />
    </div>
  );
};

export default NewsDetailsV2;
